<!--
 * @Description: 新增修改房间
 * @Author: 琢磨先生
 * @Date: 2022-05-25 08:54:59
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-08-20 13:22:45
-->

<template>
  <el-drawer
    :title="title"
    v-model="dialogVisible"
    size="1000px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="onClosed"
  >
    <el-scrollbar v-loading="loading">
      <el-alert
      type="error"
      style="margin-bottom: 30px"
      title="当房型更改时，如果新房型与旧房型非同一门店时将解除房间与房源关联关系！"
      :closable="false"
    ></el-alert>
      <el-form :model="form" ref="form" :rules="rules" label-width="100px">
        <el-form-item label="房间号" prop="house_no">
          <el-col :span="10">
            <el-input
              v-model="form.house_no"
              placeholder="请输入房号"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="房东业主" prop="landlord_id">
          <el-select
            v-model="form.landlord_id"
            filterable
            remote
            clearable
            style="width: 300px"
            :remote-method="remoteLandlord"
            placeholder="输入关键字搜索"
          >
            <el-option
              v-for="item in landlordList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <div class="help-block">请输入关键字搜索并选择房东业主</div>
        </el-form-item>

        <el-form-item label="房型" prop="group_ids">
          <!-- <el-select v-model="form.group_id" placeholder>
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in huxing_list"
              :key="item.id"
            ></el-option>
          </el-select> -->
          <el-cascader
            :options="huxing_list"
            v-model="form.group_ids"
            style="width: 300px"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="分成比例" prop="income_rule_id">
          <el-select v-model="form.income_rule_id" placeholder="请选择分成规则">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in incomerules"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="签约日期">
          <el-date-picker
            v-model="form.signed_date"
            type="date"
            :clearable="true"
            value-format="YYYY-MM-DD"
            placeholder
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="售卖日期">
          <el-date-picker
            v-model="form.sell_date"
            type="date"
            value-format="YYYY-MM-DD"
            :clearable="true"
            placeholder
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="停用">
          <el-switch v-model="form.is_stop"></el-switch>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" type="textarea" placeholder=""></el-input>
        </el-form-item>
        <el-divider>业主费用支出占比</el-divider>
        <div v-for="pt in orderTypeList" :key="pt.order_type">
          <el-divider content-position="left">
            {{ pt.type_text }}
            <el-button
              type="primary"
              size="small"
              link
              icon="edit"
              @click="onBatchRatio(pt)"
              >批量更改</el-button
            >
          </el-divider>
          <el-row>
            <el-col :span="8" v-for="item in pt.expenseList" :key="item.id">
              <el-form-item :label="item.name">
                <el-col :span="11">
                  <el-input v-model="item.value" placeholder>
                    <template #suffix>%</template>
                  </el-input>
                </el-col>
                <el-col :span="1"></el-col>
                <el-col :span="11">
                  <el-input
                    v-model="item.decimal_unit_price"
                    placeholder="单价"
                  >
                    <template #prefix>￥</template>
                  </el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </el-scrollbar>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onSubmit" :loading="saving"
          >确定</el-button
        >
      </span>
    </template>
  </el-drawer>
</template>
  
  <script>
import common_api from "@/http/common_api";
import expense_api from "@/http/expense_api";
import huxing_api from "@/http/huxing_api";
// import LandlordChoose from "@/views/components/landlord_choose";
export default {
  components: {
    // LandlordChoose
  },
  data() {
    return {
      loading: false,
      saving: false,
      dialogVisible: false,
      title: "",
      //房东列表
      landlordList: [],
      form: {},
      rules: {
        house_no: [
          {
            required: true,
            message: "名称必填",
            trigger: "blur",
          },
        ],
        landlord_id: [
          {
            required: true,
            message: "房东业主必选",
            trigger: "blur",
          },
        ],
        group_ids: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        income_rule_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      //分成规则
      incomerules: [],
      //费用项
      expenseList: [],
      //户型
      huxing_list: [],
      orderTypeList: [
        {
          order_type: 0,
          type_text: "普通订单",
        },
        {
          order_type: 1,
          type_text: "试住订单",
        },
        {
          order_type: 2,
          type_text: "自住订单",
        },
      ],
    };
  },
  emits: ["success", "close"],
  props: ["item", "shops"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = Object.assign({}, this.item);
          this.dialogVisible = true;
          if (this.item.id) {
            this.title = "修改房间";
            this.loadDtl();
          } else {
            this.title = "新增房间";
          }
          this.loadHuxing();
        }
      },
      immediate: true,
    },
    shops: {
      handler() {
        // if (this.shops) {
        //   //户型
        //   huxing_api.get_huxing().then((res) => {
        //     if (res.code == 0) {
        //       this.huxing_list = [];
        //       this.shops.forEach((item) => {
        //         var m = {
        //           value: item.id,
        //           label: item.name,
        //           children: [],
        //         };
        //         res.data
        //           .filter((o) => o.shop_id == item.id)
        //           .forEach((x) => {
        //             m.children.push({
        //               label: x.name,
        //               value: x.id,
        //             });
        //           });
        //         this.huxing_list.push(m);
        //       });
        //     }
        //   });
        // }
      },
      immediate: true,
    },
  },
  created() {
    //获取所有的费用项
    expense_api.get_expenses().then((res) => {
      if (res.code == 0) {
        this.orderTypeList.forEach((item) => {
          item.expenseList = JSON.parse(JSON.stringify(res.data));
        });
      }
    });
    common_api.get_incomerules().then((res) => {
      if (res.code == 0) {
        this.incomerules = res.data;
      }
    });
  },
  methods: {

    /**
     * 
     */
    loadHuxing(){
      if (this.shops) {
          //户型
          huxing_api.get_huxing().then((res) => {
            if (res.code == 0) {
              this.huxing_list = [];
              this.shops.forEach((item) => {
                var m = {
                  value: item.id,
                  label: item.name,
                  children: [],
                };
                res.data
                  .filter((o) => o.shop_id == item.id)
                  .forEach((x) => {
                    m.children.push({
                      label: x.name,
                      value: x.id,
                    });
                  });
                this.huxing_list.push(m);
              });
            }
          });
        }
    },

    /**
     * 加载房间详情
     */
    loadDtl() {
      this.loading = true;
      this.$http
        .get("seller/v1/houseStore/dtl?id=" + this.item.id)
        .then((res) => {
          if (res.code == 0) {
            var item = res.data;

            this.form = {
              id: res.data.id,
              house_no: item.house_no,
              landlord_id: item.landlord_id,
              group_id: item.group_id,
              income_rule_id: item.income_rule_id,
              is_stop: item.is_stop,
              group_ids: [item.huxing.shop_id, item.huxing.id],
              signed_date:item.signed_date,
              sell_date:item.sell_date,
              remark:item.remark,
            };
            if (!this.landlordList.find((o) => o.id == item.landlord_id)) {
              this.landlordList.push(item.landlord);
            }
            this.orderTypeList.forEach((m) => {
              m.expenseList.forEach((x) => {
                var exp = item.expenses.find(
                  (o) =>
                    o.expense_item_id == x.id && o.order_type == m.order_type
                );
                if (exp) {
                  x.value = exp.decimal_ratio;
                  x.decimal_unit_price = exp.decimal_unit_price;
                } else {
                  x.value = 0;
                  x.decimal_unit_price = "";
                }
              });
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          var radio_errors = [];
          this.form.expenses = [];

          this.form.group_id = this.form.group_ids[1];

          this.orderTypeList.forEach((x) => {
            x.expenseList.forEach((item) => {
              if (isNaN(item.value)) {
                radio_errors.push(`${x.type_text}-${item.name}-比例设置错误`);
              } else {
                this.form.expenses.push({
                  expense_item_id: item.id,
                  decimal_ratio: item.value ? item.value : 0,
                  order_type: x.order_type,
                  decimal_unit_price: item.decimal_unit_price,
                });
              }
            });
          });
          if (radio_errors.length > 0) {
            this.$message.info(radio_errors[0]);
            return;
          }
          this.saving = true;
          this.$http
            .post("seller/v1/houseStore/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success");
                this.dialogVisible = false;
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 远程加载房东
     * @param {*} q
     */
    remoteLandlord(q) {
      common_api.get_landlord(q).then((res) => {
        if (res.code == 0) {
          this.landlordList = res.data;
        }
      });
    },

    /**
     *
     */
    onClosed() {
      this.$emit("close");
    },

    /**
     * 批量设置费用比例
     */
    onBatchRatio(item) {
      this.$prompt("", "").then((res) => {
        if (!isNaN(res.value) && res.value <= 100 && res.value >= 0) {
          item.expenseList.forEach((x) => {
            x.value = res.value;
          });
        }
      });
    },
  },
};
</script>
  
  <style scoped>
.stores {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}
.stores .el-card {
  min-width: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.stores .name {
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  display: flex;
  align-items: center;
}
.stores .landlord {
  color: #999;
  font-size: 14px;
  margin-top: 5px;
}
.stores .actions {
  line-height: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.income_rate {
  color: #999;
  font-size: 14px;
  margin-top: 5px;
}
</style>